import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "The Piece Table",
  "date": "2019-01-24T01:00:00.000Z",
  "layout": "post",
  "draft": false,
  "path": "/posts/piece-table/",
  "category": "Software",
  "tags": ["datastructures", "beginners", "tutorial"],
  "description": "When you open a file in your text editor, the contents are read from disk and into a data structure in memory. If you were to write a text editor, how would you store an open file in memory?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The piece table is the unsung hero data-structure that is responsible for much of the functionality and performance characteristics we’ve come to expect from a text editor. Visual Studio Code has one. Microsoft Word 2.0 had one back in 1984. `}</p>
    <p>{`Despite being an almost ubiquitous feature of modern text editors, it remains relatively poorly documented. In this article, I’ll explain how a piece table is structured, why they’re used, and how your text editor interacts with it as you edit files.`}</p>
    <p>{`My aim is to make this post as beginner-friendly as possible, so we’ll explore each concept slowly. You should, however, have a basic understanding of arrays, strings, and objects (or dicts/structs) before continuing.`}</p>
    <hr></hr>
    <p>{`When you open a file in your text editor, the contents are read from disk and into a data structure in memory. If you were to write a text editor, how would you store an open file in memory?`}</p>
    <h2>{`First instincts - the array of strings`}</h2>
    <p>{`Our first instinct might be to use an array of strings, where each string is a single line in the file. In other words, we’d represent this file…`}</p>
    <pre><code parentName="pre" {...{}}>{`the quick brown fox
jumped over the lazy dog
`}</code></pre>
    <p>{`…like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`lines = [
    "the quick brown fox",       # line 1 of the file
    "jumped over the lazy dog",  # line 2 of the file
]
`}</code></pre>
    <p>{`This is an intuitive way to represent a text file in memory. It lets us think about the file in a way that is similar to how it appears on-screen in our text editor.`}</p>
    <p>{`It’s also a perfectly acceptable approach, and you could argue that the intuitiveness of it outweighs any potential flaws it may have. In fact, Visual Studio Code used a similar model until early 2018.`}</p>
    <p>{`Unfortunately, this method becomes costly when we’re dealing with large files. To see why, think about what would happen if someone were to insert a new line (`}<inlineCode parentName="p">{`"went to the park and"`}</inlineCode>{`) into the middle of the file:`}</p>
    <pre><code parentName="pre" {...{}}>{`the quick brown fox
went to the park and
jumped over the lazy dog
`}</code></pre>
    <p>{`In order to make room for this new line, all of the lines below it in the array need to be shifted along in memory. For large files, this quickly becomes expensive. As the file grows larger, there’s more data to be moved.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0be5298378b0419371ac940815c7fa3a/2cb4f/Shiftingbytes.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.282208588957054%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAADW0lEQVQoz0WT7VMTBADG96EPfanLOA+wycbLLCQDAuItJ0ohLxKHRMAQYbGxt3jNNxCYHuShvEmOSDvIEtCE47oLPASyXKQRd8DGWGyDIWNMIulf+HXN6/rw3PN8eJ7n20/gspvwOMw8c1q87rKZcNvNuO0m3LYFnq5Zeep24HHZsJp/o3PQwKmOC3yaXoX6DTXl0iL6WyJ5dFvEuXM5CHbWFnFa55ia/pU16xw7axbvoWfVwtaGneXHDzC2NDJ4Vou0poBXden4laWyrySTnIx8at/U0JxUQKMsjMKCNASDE0Z0fffJMYxTeXOS0Z+MeOzzOOYeM3q2nO+So+iPCGIgMohr0gPk5SYRVpNJyOcpHKlPRxVURGViJgM1YWg0RQjeax0lv2eSouuTyLrHiL8yTt09E+YfvqfB7y2+jQ5nSBrOoDScu1GhDIcEkVz7LpLPEjmsS0UuPsHljCyuVaejkakRRHY/5HDrGB8YJojsWeDFy2ZO3p7FPf2AjtB0vghLpnN/BHfC9tEXIKI7WExKeTzB2mjSKjJoeUdJebaC4lwdpbkaBKFtRiTNPyNq/pFdhlVevvCQwiu3uNHUzolXIrgoiqNNHE1vgBiZjw/tIWLer0pAXPk2KXVZ6LI0yPPVlOWpUeZpECTU93FEf4PdF40I5a2IVO2E676i43QFbYoS6v2jaA+OZiAwkPP+/nwtCeLgpYPEN2RSLFNQlq9FVaBFma+hNFeFYKRewr16IWcaTxJboMBX0YVE24quOJVq6VFOBcfRGRxDt1jMeV8/+l8PRPZxDqV5WpTH1RRnl1KYKafkuBJN4ScIpvRCpvR7Mep9GK4Vo65WIT6UQFxCLOqYbPTiWFr2HKBXKKRTuJfq+KMUHVOg/EhNhbyKS7XN3DRcZ2JkBMvMNIL7+gBGGwIYPS1mu3Y36zUiulqvMnb3Ds4/5pkZGuLLpGP0RMTQpKrmaquB4W9u8WhynFXzLJ6VRXY2lnnmWmbLaUHQWyJh6cwe0L+GSf4Sv9cl8qfLgXNxxkvO9qadNcsspplfWF+x8NeTJf5229h+YsWzuuglasO24NW/WdD74X6W1L50pe2iJvoFHE2HWHdYcdvmn5eXF9hcWWTLucSmd2zCtfz/wX/adDzH9R/ThGF8q33ZfgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Shiftingbytes",
            "title": "Shiftingbytes",
            "src": "/static/0be5298378b0419371ac940815c7fa3a/a6d36/Shiftingbytes.png",
            "srcSet": ["/static/0be5298378b0419371ac940815c7fa3a/222b7/Shiftingbytes.png 163w", "/static/0be5298378b0419371ac940815c7fa3a/ff46a/Shiftingbytes.png 325w", "/static/0be5298378b0419371ac940815c7fa3a/a6d36/Shiftingbytes.png 650w", "/static/0be5298378b0419371ac940815c7fa3a/e548f/Shiftingbytes.png 975w", "/static/0be5298378b0419371ac940815c7fa3a/3c492/Shiftingbytes.png 1300w", "/static/0be5298378b0419371ac940815c7fa3a/2cb4f/Shiftingbytes.png 2013w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This is just one of the downfalls of this method. In this fantastic `}<a parentName="p" {...{
        "href": "https://code.visualstudio.com/blogs/2018/03/23/text-buffer-reimplementation#_piece-tree"
      }}>{`blog post`}</a>{` by the Visual Studio Code team, they highlight other flaws with this approach such as excessive memory usage and performance issues relating to splitting up the file into multiple strings on newline characters.`}</p>
    <h2>{`An append-only representation`}</h2>
    <p>{`If we append something to the end of an array, we don’t have to shift any data along to make space for it, so we don’t incur the same performance penalty as in the case where we insert into the middle of an array (more technically, appending to an array has O(1) time complexity, but insertion is O(n)).`}</p>
    <p>{`The `}<em parentName="p">{`piece table`}</em>{` is the powerful data-structure behind many text editors, old and new. A key characteristic of the piece table is that it records all of the insertions we make to a file in an append-only manner. `}</p>
    <p>{`Let’s explore how the piece table works.`}</p>
    <p>{`When we read a file from disk into a piece table, the text inside the file is stored in a single string which we never modify. We call this string the `}<em parentName="p">{`original buffer`}</em>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`piece_table = {
    "original": "the quick brown fox\\njumped over the lazy dog",
    ...
}
`}</code></pre>
    <p>{`When we add text to the file in our editor, that text is appended to the `}<em parentName="p">{`add buffer`}</em>{` of the piece table, which is initially just an empty string.`}</p>
    <p>{`Adding a copyright notice at the top of the file? Append it to the add buffer. A new function in the middle of the file? Add buffer. An extra newline at the end of the file? Add buffer!`}</p>
    <p>{`The add buffer is the second of the two buffers in which make up a piece table, and it is append-only.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`{
    "original": "the quick brown fox\\njumped over the lazy dog",
    "add": "",
  ...
}
`}</code></pre>
    <p>{`By appending everything that gets inserted into the file onto the add buffer, we record the text that the user has typed into the editor, whilst avoiding those pesky mid-array insertions we mentioned earlier.`}</p>
    <p>{`Let’s open our hypothetical text editor again, and add a line into the middle of our file as we did before. That makes our piece table look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`{
    "original": "the quick brown fox\\njumped over the lazy dog",
    "add": "went to the park and\\n",
  ...
}
`}</code></pre>
    <p>{`The text we added to the middle of the file is in the add buffer. The original buffer has stayed the same (and always will).`}</p>
    <p>{`These two strings, `}<inlineCode parentName="p">{`original`}</inlineCode>{` and `}<inlineCode parentName="p">{`add`}</inlineCode>{`, hold within them the entire contents of the file that is open in your editor, as well as everything that has ever been contained in the file since it was opened.`}</p>
    <p>{`When the editor displays an open file, it combines different sections of these two strings together to form the what you see on your screen. Some sections of these strings may be ignored if they’re no longer present in the file (for example if the user of the text editor deletes some text). `}</p>
    <p>{`In the text below, the middle section comes from the add buffer since it was inserted, and the rest of the text comes from the original buffer since it was part of the original file.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e4d04547646f14253eb1632368fc7b79/04784/PieceTableBasic.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "5.521472392638037%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAUUlEQVQI1x3LMQ5AQBBAUfc/hgTDTTTC2kUjCtFtZgeJ9gvVq152hxobhTQI5gTzDVefc2wtPj3MUZn1ZIpK0Jt1X7ChRPuSaxRO993qN3YFLxUfRewa2I7FAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "PieceTableBasic",
            "title": "PieceTableBasic",
            "src": "/static/e4d04547646f14253eb1632368fc7b79/a6d36/PieceTableBasic.png",
            "srcSet": ["/static/e4d04547646f14253eb1632368fc7b79/222b7/PieceTableBasic.png 163w", "/static/e4d04547646f14253eb1632368fc7b79/ff46a/PieceTableBasic.png 325w", "/static/e4d04547646f14253eb1632368fc7b79/a6d36/PieceTableBasic.png 650w", "/static/e4d04547646f14253eb1632368fc7b79/e548f/PieceTableBasic.png 975w", "/static/e4d04547646f14253eb1632368fc7b79/04784/PieceTableBasic.png 1174w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Right now, our hypothetical editor knows the user has inserted the string `}<inlineCode parentName="p">{`"went to the park and\\n"`}</inlineCode>{` into the file, but not `}<em parentName="p">{`where`}</em>{` they inserted it. We haven’t yet got enough information in the piece table for the editor to be able to correctly display the contents of the file. The missing piece of the puzzle (pun intended) is to track where in the document the user inserted the text.`}</p>
    <h2>{`Piece descriptors`}</h2>
    <p>{`In order to know where the user inserted the text in the file, the piece table needs to track which sections of the file come from the `}<inlineCode parentName="p">{`original`}</inlineCode>{` buffer, and which sections come from the `}<inlineCode parentName="p">{`add`}</inlineCode>{` buffer. It does this by iterating through a list of `}<em parentName="p">{`piece descriptors`}</em>{`. A piece descriptor contains three bits of information:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`source`}</inlineCode>{`: tells us which buffer to read from.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`start`}</inlineCode>{`: tells us which index in that buffer to start reading from.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`length`}</inlineCode>{`: tells us how many characters to read from that buffer.`}</li>
    </ul>
    <p>{`When we first open a file in our editor, only the `}<inlineCode parentName="p">{`original`}</inlineCode>{` buffer has content, and there’s a single piece descriptor which tells our editor to read entirely from the `}<inlineCode parentName="p">{`original`}</inlineCode>{` buffer. The add buffer is empty because we haven’t yet added any text to our file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`{
    "original": "the quick brown fox\\njumped over the lazy dog",
    "add": "",
  "pieces": [Piece(start=0, length=44, source="original")],
}
`}</code></pre>
    <h2>{`Adding text to a file`}</h2>
    <p>{`Now let’s add the same text to the middle of our file just like before. Here’s how the piece table is updated to reflect this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`{
    "original": "the quick brown fox\\njumped over the lazy dog",
      "add": "went to the park and\\n",
    "pieces": [
      Piece(start=0, length=20, source="original"),
      Piece(start=0, length=21, source="add"),
      Piece(start=20, length=24, source="original"),
    ],
}
`}</code></pre>
    <p>{`The text we inserted in our file using our text editor has been appended to the add buffer, and what was previously a single piece is now three.`}</p>
    <ul>
      <li parentName="ul">{`The first piece in the list now tells us that only the first 20 characters of the original buffer (`}<inlineCode parentName="li">{`the quick brown fox\\n`}</inlineCode>{`) make up the first span of text in our file (note that `}<inlineCode parentName="li">{`\\n`}</inlineCode>{`, representing a line break, is a single character).`}</li>
      <li parentName="ul">{`The second piece tells us that the next 21 characters of the file can be found between indices 0 and 21 in the add buffer (`}<inlineCode parentName="li">{`went to the park and\\n`}</inlineCode>{`).`}</li>
      <li parentName="ul">{`The third and final piece tells us that the final span of text in the file can be found between indices 20 and 44 (start + length) of the original buffer (`}<inlineCode parentName="li">{`jumped over the lazy dog`}</inlineCode>{`).`}</li>
    </ul>
    <p>{`The act of inserting text into a file typically results in splitting a piece into three separate pieces:`}</p>
    <ol>
      <li parentName="ol">{`One piece to point to the text that falls to the left of the newly inserted text.`}</li>
      <li parentName="ol">{`Another piece to refer to the inserted text itself (in the add buffer). `}</li>
      <li parentName="ol">{`The third piece refers to the text that got pushed to the right of the newly inserted text.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/02d0ff444395a31af8eb5192725abce1/04784/PieceTableExample.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.37423312883436%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABqklEQVQoz5WT3U7bQBCF/f6vUpWA1LtKvUTQXFIgCqAmAUUm2PvnxPvvr/ImqYJoK/VIn2Z3NGdtz46rYRgYGeWcQymF0prgPaa3rLVhs+tLHKm7LctGsLO2eE79o6rTRIyxHBpCKLERgo2U9DHSOVfYOl9IOf/7wNPksVBKyXq9RivFn3TqPVJ9KDowymhNXde8bTZIKX4/dODvqoboGJIjH2LZ54ALETt+eoz4mEqMOTMkX2pKfTzxFTxVN7tA3U4w9+eouwnd3Wfax2/MpeZRb3mQpqwflGHeStr5V9TNJ7azC7r7c/TtGeZugvpxRjf7QrUVa1LfMjhJ6gXZCqIz2JiwKWNTOqz3JKcJ3Rv67Rmrawa79xWspFrXrzRNg/ee/5EQsvS3bcW7nlar1YqXlxc2rzVaCTqjYcgMheHDBRz34yUtl6syBUK0aK3LqFV5bHTOpJRw1hKDJ0VPLjhS6A/YfS5You8JricnXwhui7e7MpvV+GcIITDGoKQkhIh5fUI8XSIXU/Ryivh5jV5e0zxdUc8vUYvvJd89T1HLKWpxRbO6KW//C0bNUPrSjKfQAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "PieceTableExample",
            "title": "PieceTableExample",
            "src": "/static/02d0ff444395a31af8eb5192725abce1/a6d36/PieceTableExample.png",
            "srcSet": ["/static/02d0ff444395a31af8eb5192725abce1/222b7/PieceTableExample.png 163w", "/static/02d0ff444395a31af8eb5192725abce1/ff46a/PieceTableExample.png 325w", "/static/02d0ff444395a31af8eb5192725abce1/a6d36/PieceTableExample.png 650w", "/static/02d0ff444395a31af8eb5192725abce1/e548f/PieceTableExample.png 975w", "/static/02d0ff444395a31af8eb5192725abce1/04784/PieceTableExample.png 1174w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Things are a little different when you insert text at the beginning or end of an existing piece. In this case, adding text doesn’t “split” an existing piece in half, so we only need a single extra piece to represent the newly inserted text.`}</p>
    <h2>{`Saving and displaying the open file`}</h2>
    <p>{`As mentioned at the very beginning of this post, when we open a file in a text editor, it gets read from disk and stored inside a data structure in memory (very likely a piece table or some variant). Likewise, when we do the inverse operation and save the file, our editor needs to be able to read the piece table and write the contents back to the file on disk.`}</p>
    <p>{`By reading the piece descriptors in consecutive order, our text editor can transform its internal representation of the file in the piece table into what you see on your screen, and what gets written to the file when you save it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`for piece in piece_table["pieces"]:
    source: str = piece.source  # "original" or "add"
    buffer: str = piece_table[source]
    span_of_text: str = buffer[piece.start:piece.start+piece.length]
    print(span_of_text)  # alternatively, write to a file
`}</code></pre>
    <p><em parentName="p">{`Note:`}</em>{` In Python, the `}<inlineCode parentName="p">{`string[start:end]`}</inlineCode>{` syntax can be used to return a substring from a string. For example, `}<inlineCode parentName="p">{`“hello”[1:3]`}</inlineCode>{` returns `}<inlineCode parentName="p">{`"el"`}</inlineCode>{`.`}</p>
    <h2>{`Deleting text`}</h2>
    <p>{`When we delete some text from a file, we split an existing piece into two pieces:`}</p>
    <ol>
      <li parentName="ol">{`One piece points to the text to the left of the deleted text.`}</li>
      <li parentName="ol">{`A second piece points to the text to the right of the deleted text.`}</li>
    </ol>
    <p>{`The deleted text will still be present in one of our buffers, but with no pieces referring to it, it is not considered part of our file.`}</p>
    <p>{`You can think of piece descriptors like spotlights pointed at a wall with all of the text ever written to a file painted on it. At any point in time, these lights illuminate sections of the wall, revealing the text there. The illuminated text is considered as part of the file. Although we know there’s more written on the wall, it’s hidden in the darkness and considered irrelevant.`}</p>
    <h2>{`Undoing and redoing`}</h2>
    <p>{`One benefit of keeping all of that text around even when it’s not currently part of the file is that it makes the implementation of undo/redo considerably easier.`}</p>
    <p>{`Text that is currently “in darkness” may be required again in the future. Wouldn’t it great if we could just adjust our lighting to illuminate that text once again, instead of having to repaint the wall?`}</p>
    <p>{`In the array of strings approach discussed at the start of this post, an undo operation could result in millions of characters of text being inserted or deleted across millions of different strings. With the piece table method, this cost of this action becomes trivial. The text to be added or removed as part of an undo or redo is already there in one of the buffers, and we just need to update our piece descriptors to point to it once again.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`There are several ways we could improve the piece table described above. They’re often combined with other data structures such as trees in order to improve aspects of their performance.`}</p>
    <p>{`That said, my goal for this post was to leave you with intuition and some appreciation for how your text editor works internally, and those tweaks fall out with that goal.`}</p>
    <p>{`If you have any constructive feedback on this post, or if you’ve noticed any errors in it, let me know!`}</p>
    <p>{`If you’re interested in more content like this, follow me on `}<a parentName="p" {...{
        "href": "https://twitter.com/_darrenburns"
      }}>{`Twitter`}</a>{`, `}<a parentName="p" {...{
        "href": "https://dev.to/_darrenburns"
      }}>{`DEV`}</a>{`, and check out my `}<a parentName="p" {...{
        "href": "https://darrenburns.net/"
      }}>{`blog`}</a>{`!`}</p>
    <h2>{`References & further reading`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.cs.unm.edu/~crowley/papers/sds.pdf"
        }}>{`Data Structures For Text Sequences (PDF)`}</a>{` - Charles Crowley`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://web.archive.org/web/20160308183811/http://1017.songtrellisopml.com/whatsbeenwroughtusingpiecetables"
        }}>{`What’s been wrought using the Piece Table?`}</a>{` - David Lu (I think)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://code.visualstudio.com/blogs/2018/03/23/text-buffer-reimplementation#_piece-tree"
        }}>{`Text Buffer Reimplementation, a Visual Studio Code Story`}</a>{` - Peng Lyu`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      